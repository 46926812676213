  .container {
    display: grid;
    grid-template-columns: auto 2fr auto;
    background-color: #121214;
    padding: 1rem;

    .whitespace {
      width: 40px
    }

    .controls {
      display: flex;
      justify-content: center;
      gap: 80px;

      @media screen and (max-width: 768px) {
          gap: 50px;
      }
    }

    .controlButton {
      width: 40px;
      height: 40px;
      color: white;
      background-color: black;
      border: none;

      svg {
        width: 28px;
        height: 28px;
      }

      &:disabled {
        opacity: 0.5;
      }
    }
  }
