.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  // row-gap: 1rem;
  // column-gap: 2rem;

  .iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 32px;
    height: 32px;
    border-radius: 16px;
  }

  a {
    margin: 0.5rem 1rem;
  }
}
