.container {
  display: flex;

  flex-direction: column;
  padding: 3rem 1rem;

  > * {
    margin-bottom: 2rem;
  }

  > :last-child {
    margin-bottom: 0;
  }
}
