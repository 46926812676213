.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 54px;
  
    button {
      padding: 1rem 4.5rem;
      border-radius: 4.8px;
      width: 100%;
      height: 100%;
  
      font-size: 17.6px;
      text-align: center;
  
      text-decoration: none;
  
      transition: transform 0.3s;
  
      &:hover {
        transform: scale(1.02);
      }
    }
  }
  