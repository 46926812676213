* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --link-text-color: #000000;

  --white: #ffffff;
}

body,
button,
input,
textarea {
  font: 400 1rem 'Roboto', sans-serif;
}
