.container {
  margin: 0 auto;
  min-height: 360px;

  overflow: auto;

  display: flex;
  justify-content: center;
  height: 100vh;

  background-color: '#FFFFFF';
  opacity: 0;
  transition: opacity 0.4s ease-in;

  .content {
    @media screen and (max-width: 990px) {
      max-width: 480px;
    }

    @media screen and (max-width: 770px) {
      max-width: 540px;
    }

    @media screen and (max-width: 580px) {
      max-width: 100%;
    }
  }

  .error {
    color: #222;
    text-align: center;
    padding: 48px 0;
    height: 100vh;
  }

  .offersModal {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;

    background-color: #000000aa;
  }
}
